import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { List } from '@material-ui/core';
import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    button:{
        color: 'white',
        backgroundColor: theme.palette.primary['800'],
        borderRadius:'initial',
        '&:hover':{
            backgroundColor:theme.palette.primary['800']
        }
    },
    grid:{
        [theme.breakpoints.down('sm')]:{
            padding: theme.spacing(1, 1, 1, 2)
        },
        [theme.breakpoints.up('sm')]:{
            padding: theme.spacing(2, 1, 2, 3)
        },
        [theme.breakpoints.up('md')]:{
            padding: theme.spacing(3, 1, 3, 10)
        }
        
    },
    footer:{
        width: "100%",
        
    },
   root:{
       backgroundColor: theme.palette.primary['main']
   },
   typo:{
       [theme.breakpoints.down('xs')]:{
           margin:'auto'
       },
       width:'100%'
   },
   img:{
    height: '30px',
    width: '30px'
   }
}))

function Footer() {
    const classes = useStyles();
    const history = useHistory()
    //サブスクリプション登録画面へ

    const handleHelp = () => {
        history.push('/help')
    }

    const handleSubscriptionRegister = () => {
        history.push('/subscription/plan')
    }

    const handleSpecifiedCommercialComponent = () => {
        history.push('/specified_commercial')
    }

    const handleTerm = () => {
        history.push('/terms')
    }

    const handlePrivacyPolicy = () => {
        history.push('/privacy_policy')
    }

    const handleLineEstimate = () => {
        history.push('/line_estimate')
    }

    //const handleCorporate = () => {
        //window.location.replace('https://enclopedia.jp')
    //}

    //const handleNews = () => {
        //window.location.replace('https://enclopedia.jp/category/news/')
    //}

    const handleInsta = () => {
        window.location.replace('https://www.instagram.com/enclopedia.official/')
    }

    const handleLine = () => {
        window.location.replace('https://lin.ee/jsSHCdF')
    }

    //const handleCustomer = () => {
        //window.location.replace('https://enclopedia.jp/contact/')
    //}

    const handleLicense = () => {
        history.push('/antique_dealer_license')
    }

    const handlePrice = () => {
        history.push('/help_price')
    }

    const handleDelivery = () => {
        history.push('/help_delivery')
    }

    const handleReservation = () => {
        history.push('/help_reservation')
    }

    const handleStart = () => {
        history.push('/help_guide')
    }


    return (
        <div className={classes.root}>
            <Grid container className={classes.grid}>
                <Grid key='companyInfo' item mobile={12} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <List>
                        <ListItem key="title_enclopedia" style={{textAlign:'center'}}>
                            <Typography variant="subtitle1" component="p" className={classes.typo} style={{fontWeight:'bold', width:'100%'}} align="center">Enclopediaについて</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/help_guide" key="about_enclopedia" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}} onClick={handleStart}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center">Enclopediaをはじめる前に｜使い方</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/line_estimate" key="line_estimate" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}} onClick={handleLineEstimate}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center">LINE査定</Typography>
                        </ListItem>
                        <ListItem component={Link} path="https://www.instagram.com/enclopedia.official/"  key="instagram" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}} onClick={handleInsta}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center">Instagram</Typography>
                        </ListItem>
                        <ListItem component={Link} path="https://lin.ee/jsSHCdF"  key="instagram" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}} onClick={handleLine}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center">LINE</Typography>
                        </ListItem>
                        {/*
                        <ListItem component={Link} path="https://enclopedia.jp/"  key="about_enclopedia" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}} onClick={handleCorporate}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center">Enclopediaホームページ</Typography>
                        </ListItem>
                        <ListItem component={Link} path="'https://enclopedia.jp/category/news/'" key="news_enclopedia" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography variant="subtitle2" className={classes.typo} component="p" align="center" onClick={handleNews}>ニュース</Typography>
                        </ListItem>
                        */}
                    </List>
                </Grid>
                <Grid  key='service' item mobile={12} xs={12} sm={12} md={4} lg={4} xl={4}>
                    <List>
                        <ListItem key="enclopdia_service_guide">
                            <Typography variant="subtitle1" className={classes.typo} component="p" align='center' style={{fontWeight:'bold'}}>サービスガイド</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/subscription/plan" onClick={handleSubscriptionRegister} key="enclopedia_subscription" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                                <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>サブスクリプション登録</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/help_reservation" onClick={handleReservation} key="enclopedia_product_management" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>レンタル方法</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/help_price" onClick={handlePrice} key="enclopedia_price" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>料金と購入</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/help_delivery" onClick={handleDelivery} key="enclopdia_shipping_method" style={{paddingBottom:'initial', cursor: 'pointer', color:'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>返却方法</Typography>  
                        </ListItem>
                    </List>
                </Grid>
                <Grid key='support' item mobile={12} xs={12} sm={12} md={4} lg={4} xl={4}>
                <List>
                        <ListItem key="enclopedia_support">
                            <Typography className={classes.typo} variant="subtitle1" component="p" style={{fontWeight:'bold'}} align='center'>サポート</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/help" onClick={handleHelp} key="enclopdia_help" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>ヘルプ</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/terms" onClick={handleTerm} key="enclopedia_term_of_service" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" style={{cursor:'pointer'}} align='center'>利用規約</Typography>  
                        </ListItem>
                        <ListItem component={Link} path="/privacy_policy" onClick={handlePrivacyPolicy} key="enclopedia_privacy_policy" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" style={{cursor:'pointer'}} align='center'> プライバシーポリシー</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/specified_commercial" onClick={handleSpecifiedCommercialComponent} key="enclopedia_specified_commercial_code" style={{paddingBottom:'initial', cursor:'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>特定商取引に基づく表示</Typography>
                        </ListItem>
                        <ListItem component={Link} path="/antique_dealer_license" onClick={handleLicense} key="enclopedia_specified_commercial_code" style={{paddingBottom:'initial', cursor:'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>古物営業法に基づく表示</Typography>
                        </ListItem>
                        {/*
                        <ListItem component={Link} path="https://enclopedia.jp/contact/" onClick={handleCustomer} key="enclopedia_contact" style={{paddingBottom:'initial', cursor: 'pointer', color: 'black'}}>
                            <Typography className={classes.typo} variant="subtitle2" component="p" align='center'>お問合せ</Typography>
                        </ListItem>
                        */}
                    </List>
                </Grid>
        </Grid>
        </div>
    )
}

export default Footer
