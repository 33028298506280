import Header from './components/Header'
import Footer from './components/Footer'
import {Provider} from 'react-redux'
import store from './store'
import{ BrowserRouter  as Router, Route} from 'react-router-dom';
//import {makeStyles} from '@material-ui/core/Typography'
import { Adress } from './components/ProfileManagement/Adress';
import AddressUpdateScreen from './screens/AddressUpdateScreen';
import AuthenticationScreen from './screens/AuthenticationScreen';
import AntiqueDealerLicenseScreen from './screens/AntiqueDealerLicenseScreen';
import BrandListScreen from './screens/BrandScreen';
import BrandProductScreen from './screens/BrandProductScreen';
import BuyingConfirmScreen from './screens/BuyingConfirmScreen';
import CartScreen from './screens/CartScreen'
import CheckoutStep from './screens/CheckoutStep';
import CreditInfoScreen from './screens/CreditInfoScreen';
import CreditInfoUpdateScreen from './screens/CreditInfoUpdateScree ';
import CssBaseline from "@material-ui/core/CssBaseline";
import DeliveryHelpScreen from './screens/DeliveryHelpScreen';
import EmailUpdateScreen from './screens/EmailUpdateScreen';
import { Container } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import FavoriteProductScreen from './screens/FavoriteProductScreen';
import HelpScreen from './screens/HelpScreen';
import HomeScreen from './screens/HomeScreen'
import { InvoiceScreen } from './components/ProfileManagement/Invoice';
import IdeaScreen from './screens/IdeaScreen';
import InvitationCouponScreen from './screens/InvitationCouponScreen';
import LoginScreen from './screens/LoginScreen';
import NotificationScreen from './screens/NotificationScreen';
import NotificationListScreen from './screens/NotificationListScreen';
import RegisterScreen from './screens/RegisterScreen';
import ManagementScreen from './screens/ManagementScreen';
import OrderItemHistoryScreen from './screens/OrderItemHistoryScreen';
import PasswordReset from './components/ProfileManagement/ForgetPasswordComponent';
import PasswordResetEmail from './components/ProfileManagement/PasswordResetEmail';
import PasswordChangeScreen from './components/ProfileManagement/PasswordChange';
import PhoneNumberUpdateScreen from './screens/PhoneNumberUpdateScreen';
import PhoneNumberRegisterScreen from './screens/PhoneNumberRegisterScreen';
import PriceHelpScreen from './screens/PriceHelpScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ProductScreen from './screens/ProductScreen'
import ProfileScreen from './screens/ProfileScreen';
import ProvisionalRegistrationScreen from './screens/ProvisinalRegistrationScreen';
import PurChaseConfirmedScreen from './screens/PurchaseConfirmedScreen';
import ScrollToTop from './components/ScrollToTop';
import SearchBarComponent from './components/SearchComponent';
import SearchProductScreen from './screens/SearchProductScreen'
import SecurityInfo from './screens/UserSecretInfo';
import SpecifiedCommercialScreen from './screens/SpecifiedCommercialScreen';
import StartGuideScreen from './screens/StartGuideScreen';
import TagProductScreen from './screens/TagProductScreen';
import TermOfService from './screens/TermOfServiceScreen';
import TodoScreen from './screens/TodoScreen';
import ReservationHelpScreen from './screens/ReservationHelpScreen';
import ReservationHistoryScreen from './screens/ReservationHistory';
import ReviewScreen from './screens/ReviewScreen';
import RegisterCompleteScreen from './screens/RegisterCompleteScreen';
//test
import ShippingForm from './screens/ShippingScreen';
import CreditListScreen from './screens/CreditListScreen';
import AddCreditScreen from './screens/CreditAddScreen';
import ReservationCalendar from './components/CalendarComponent';
import LineEstimate from './screens/LineEstimateScreen';
//import ChatgptImageForm from './components/chatgptComponent';
//strip component
import StripePlanScreen from './screens/StripePlanScreen';
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { loadStripe } from '@stripe/stripe-js';
//react-helmet-async
import {HelmetProvider} from 'react-helmet-async';

//publickeyは随時記述するのではなくバックエンド側から取ってくるようにする
const  App = () => {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
  //darkmode切り替えはheader内で行えるようにする
  //useSele
 
  const theme = createMuiTheme({
    palette: {
      background: {
        default: '#eeeeee',
      },
      primary: {
        'main': '#eeeeee',
        '50' :'#fafafa',
        '100': '#f5f5f5',
        '200': '#eeeeee',
        '300': '#e0e0e0',
        '400': '#bdbdbd',
        '500': '#9e9e9e',
        '600': '#757575',
        '700': '#616161',
        '800': '#424242',
        '900': '#212121',
      },
      secondary:{
        '50': '#fff8e1',
        '100': '#ffecb3',
        '200': '#ffe082',
        '300': '#ffd54f',
        '400': '#ffca28',
        '500': '#ffc107',
        '600': '#ffb300',
        '700': '#ffa000',
        '800': '#ff8f00',
        '900': '#ff6f00',
        'A100': '#ffe57f',
        'A200' : '#ffd740',
        'A400' : '#ffc400',
        'A700': '#ffab00',
      }},
    typography: {
        fontFamily: [
          'Helvetica Neue',
          'Arial',
          'Hiragino Kaku Gothic ProN',
          'Hiragino Sans',
          'sans-serif',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),

        subtitle1:{
          fontSize:14,
        },
        subtitle2:{
          fontSize:12,
        },
        h1:{
          fontSize:24,
        },
        h2:{
          fontSize: 20,
        },
        h3:{
          fontSize:16,
        },
        h4:{
          fontSize:36
        },
        h5:{
          fontSize:20
        },
        h6:{
          fontSize:10
        }
      }
    ,

    'breakpoints': {
      'keys': [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
      ],
      'values': {
        'xs': 0,
        'sm': 431,
        'md': 769,
        'lg': 992,
        'xl': 1300,
      },
    },
  });

  theme.typography.subtitle1 = {
    [theme.breakpoints.down('xs')]:{
      fontSize: 12
    },
    [theme.breakpoints.up('xl')]:{
      fontSieze: 16
    }
  }
  theme.typography.subtitle2 = {
    [theme.breakpoints.down('xs')]:{
      fontSize: 9
    },
    [theme.breakpoints.up('xs')]:{
      fontSize: 10
    }
  }


  //appコンポーネント内では
  return (
    <HelmetProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Elements stripe={stripePromise}>
      <Provider store={store}>
        <Router>
          <ScrollToTop />
            <Container maxWidth={'xl'} style={{padding:'initial'}}>
            <Header />
            <SearchBarComponent/>
            <main>
              <Route exact path='/' component={HomeScreen}/>
              <Route path="/address" component = {Adress} />
              <Route path="/address_update" component={AddressUpdateScreen}/>
              <Route path="/antique_dealer_license" component={AntiqueDealerLicenseScreen} />
              <Route path="/phone_number_authentication" component={AuthenticationScreen}/>
              <Route path="/buying/:id" component={BuyingConfirmScreen}/>
              <Route path="/brands" component={BrandListScreen}/>
              <Route path='/brand/:id/' component={BrandProductScreen}/>
              <Route path='/cart/:id' component={CartScreen} />
              <Route path="/checkout/:id" component={CheckoutStep} />
              <Route path="/add_credit" component={AddCreditScreen}/>
              <Route path="/credit" component={CreditInfoScreen} />
              <Route path="/credit_list" component={CreditListScreen} />
              <Route path="/credit_card/update" component={CreditInfoUpdateScreen}/>
              <Route path='/email_update' component={EmailUpdateScreen}/>
              <Route path='/favorite/product/list' component={FavoriteProductScreen}/>
              <Route path='/help' component={HelpScreen}/>
              <Route path='/help_guide' component={StartGuideScreen}/>
              <Route path='/help_price' component={PriceHelpScreen}/>
              <Route path='/help_delivery' component={DeliveryHelpScreen}/>
              <Route path="/help_reservation" component={ReservationHelpScreen}/>
              <Route path='/login' component={LoginScreen} />
              <Route path='/idea' component={IdeaScreen}/>
              <Route path='/invitation_coupon' component={InvitationCouponScreen} />
              <Route path='/line_estimate' component={LineEstimate}/>
              <Route path="/notification/:id" component={NotificationScreen} />
              <Route path='/notifications' component={NotificationListScreen} />
              <Route path="/management" component={ManagementScreen} />
              <Route path="/order/history/:id" component={OrderItemHistoryScreen} />
              <Route path='/passwrod_reset/email/confirm' component={PasswordResetEmail}/>
              <Route path='/password_reset/password/confirm' component={PasswordReset}/>
              <Route path='/password/change' component={PasswordChangeScreen}/>
              <Route path='/phone_number_update' component={PhoneNumberUpdateScreen}/>
              <Route path='/phone_number_register' component={PhoneNumberRegisterScreen}/>
              <Route path='/privacy_policy' component={PrivacyPolicyScreen}/>
              <Route path='/product/:id' component={ProductScreen} />
              <Route path='/profile' component={ProfileScreen} /> 
              <Route path='/provisianl_registration' component={ProvisionalRegistrationScreen}/>
              <Route path='/purchase/item/:id/' component={PurChaseConfirmedScreen}/>
              <Route path='/register' component={RegisterScreen} />
              <Route path='/search/:id/' component={SearchProductScreen} />
              <Route path="/subscription/plan" component={StripePlanScreen} />
              <Route path="/subscription/info" component={InvoiceScreen}/>
              <Route path="/specified_commercial" component={SpecifiedCommercialScreen}/>
              <Route path="/reservation_history" component={ReservationHistoryScreen}/>
              <Route path="/register_confirmed/:id" component={RegisterCompleteScreen}/>
              <Route path='/review/:id' component={ReviewScreen}/>
              <Route path="/security" component={SecurityInfo} />
              <Route path='/shipping/:id' component={ShippingForm} />
              <Route path='/tag/:id/' component={TagProductScreen} />
              <Route path='/terms' component={TermOfService}/>
              <Route path="/todo" component={TodoScreen}/>
              <Route path="/calendar" component={ReservationCalendar}/>
              {/*<Route path="/styling" component={ChatgptImageForm}/>*/}
            </main>
            </Container>
            <Footer/>
        </Router>
      </Provider>
      </Elements>
    </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
